import store from '@/store'
/**
 * Created by jiachenpan on 16/11/18.
 */


export function parseType(ibType){
  const ibTypeOptions=store.getters && store.getters.ibTypeOptions
  for (var i = ibTypeOptions.length - 1; i >= 0; i--) {
    if(ibTypeOptions[i].key==ibType){
      return ibTypeOptions[i].display_name
    }
  }
  return ibType
}

export function parseIbStatus(ibStatus){
  const ibStatusOptions=store.getters && store.getters.ibStatusOptions
  for (var i = ibStatusOptions.length - 1; i >= 0; i--) {
    if(ibStatusOptions[i].key==ibStatus){
      return ibStatusOptions[i].display_name
    }
  }
  return ibStatus
}
export function parseCommiStatus(commiStatus){
  const commiStatusOptions=store.getters && store.getters.commiStatusOptions
  for (var i = 0; commiStatusOptions!=null&&i < commiStatusOptions.length; i++) {
    if(commiStatusOptions[i].key==commiStatus){
      return commiStatusOptions[i].display_name
    }
  }
  return commiStatus
}
export function parseBusStatus(busStatus){
  const busSecStatusOptions=store.getters && store.getters.busSecStatusOptions
  for (var i = busSecStatusOptions.length - 1; i >= 0; i--) {
    if(busSecStatusOptions[i].key==busStatus){
      return busSecStatusOptions[i].display_name
    }
  }
  return busStatus
}
export function parseCommiType(commiType){
  const commiTypeOptions=store.getters && store.getters.commiTypeOptions
  for (var i = commiTypeOptions.length - 1; i >= 0; i--) {
    if(commiTypeOptions[i].key==commiType){
      return commiTypeOptions[i].display_name
    }
  }
  return commiType
}
export function parseCurrencyType(currencyType){
  const currencyTypeOptions=store.getters && store.getters.currencyTypeOptions
  for (var i = currencyTypeOptions.length - 1; i >= 0; i--) {
    if(currencyTypeOptions[i].key==currencyType){
      return currencyTypeOptions[i].display_name
    }
  }
  return currencyType
}

export function parseCommiLvl(lvl){
  const levelOptions=store.getters && store.getters.levelOptions
  for (var i = levelOptions.length - 1; i >= 0; i--) {
    if(levelOptions[i].key==lvl){
      return levelOptions[i].display_name
    }
  }
  return lvl
}

export function parseCurrencyPair(currencyPair){
  const currencyPairOptions=store.getters && store.getters.currencyPairOptions
  for (var i = currencyPairOptions.length - 1; i >= 0; i--) {
    if(currencyPairOptions[i].key==currencyPair){
      return currencyPairOptions[i].display_name
    }
  }
  return currencyPair
}

export function parseComputeType(computeType){
  const computeTypeOptions=store.getters && store.getters.computeTypeOptions
  for (var i = computeTypeOptions.length - 1; i >= 0; i--) {
    if(computeTypeOptions[i].key==computeType){
      return computeTypeOptions[i].display_name
    }
  }
  return computeType
}

export function parseIsDefault(isDefault){
  const isDefaultOptions=store.getters && store.getters.isDefaultOptions
  for (var i = isDefaultOptions.length - 1; i >= 0; i--) {
    if(isDefaultOptions[i].key==isDefault){
      return isDefaultOptions[i].display_name
    }
  }
  return isDefault
}

export function parseCommiBase(commiBase){
  const commiBaseOptions=store.getters && store.getters.commiBaseOptions
  for (var i = commiBaseOptions.length - 1; i >= 0; i--) {
    if(commiBaseOptions[i].key==commiBase){
      return commiBaseOptions[i].display_name
    }
  }
  return commiBase
}

export function parseSource(source){
  const sourceOptions=store.getters && store.getters.sourceOptions
  for (var i = sourceOptions.length - 1; i >= 0; i--) {
    if(sourceOptions[i].key==source){
      return sourceOptions[i].display_name
    }
  }
  return source
}

export function parseIbRank(rank){
  const ibRankOptions=store.getters && store.getters.ibRankOptions
  for (var i = ibRankOptions.length - 1; i >= 0; i--) {
    if(ibRankOptions[i].key==rank){
      return ibRankOptions[i].display_name
    }
  }
  return rank
}

export function parseRewardType(rewardType){
  const rewardTypeOptions=store.getters && store.getters.rewardTypeOptions
  for (var i = rewardTypeOptions.length - 1; i >= 0; i--) {
    if(rewardTypeOptions[i].key==rewardType){
      return rewardTypeOptions[i].display_name
    }
  }
  return rewardType
}

export function parseCommType(commType){
  const commTypeOptions=store.getters && store.getters.commTypeOptions
  for (var i = commTypeOptions.length - 1; i >= 0; i--) {
    if(commTypeOptions[i].key==commType){
      return commTypeOptions[i].display_name
    }
  }
  return commType
}

export function parseDirectCommType(directCommType){
  const directCommTypeOptions=store.getters && store.getters.directCommTypeOptions
  for (var i = directCommTypeOptions.length - 1; i >= 0; i--) {
    if(directCommTypeOptions[i].key==directCommType){
      return directCommTypeOptions[i].display_name
    }
  }
  return directCommType
}

export function parseIndirectCommType(indirectCommType){
  const indirectCommTypeOptions=store.getters && store.getters.indirectCommTypeOptions
  for (var i = indirectCommTypeOptions.length - 1; i >= 0; i--) {
    if(indirectCommTypeOptions[i].key==indirectCommType){
      return indirectCommTypeOptions[i].display_name
    }
  }
  return indirectCommType
}

export function parseIsValid(isValid){
  const isValidOptions=store.getters && store.getters.isValidOptions
  for (var i = isValidOptions.length - 1; i >= 0; i--) {
    if(isValidOptions[i].key==isValid){
      return isValidOptions[i].display_name
    }
  }
  return isValid
}

export function parseGroup(group){
  const groupOptions=store.getters && store.getters.groupOptions
  for (var i = groupOptions.length - 1; i >= 0; i--) {
    if(groupOptions[i].key==group){
      return groupOptions[i].display_name
    }
  }
  return group
}

export function parseMTGroup(groupName){
  const mtGroups=store.getters && store.getters.mtGroups
  for (var i = 0;mtGroups!=null && i <=  mtGroups.length - 1; i++) {
    if(mtGroups[i].id==groupName){
      return mtGroups[i].groupName
    }
  }
  return groupName
}

export function parseCategorys(catName){
  const categorys=store.getters && store.getters.categorys
  for (var i = 0;categorys!=null && i <=  categorys.length - 1; i++) {
    if(categorys[i].id==catName){
      return categorys[i].catName
    }
  }
  return catName
}

export function parseBussSectors(bussSector){
  const bussSectors=store.getters && store.getters.bussSectors
  for (var i = 0;bussSectors!=null && i <=  bussSectors.length - 1; i++) {
    if(bussSectors[i].id==bussSector){
      return bussSectors[i].sectorName
    }
  }
  return bussSector
}

export function parseCusStatus(cusStatus){
  const cusStatusOptions=store.getters && store.getters.cusStatusOptions
  for (var i = cusStatusOptions.length - 1; i >= 0; i--) {
    if(cusStatusOptions[i].key==cusStatus){
      return cusStatusOptions[i].display_name
    }
  }
  return cusStatus
}

export function parseCusAuditStatus(auditStatus){
  const cusAuditStatusOptions=store.getters && store.getters.cusAuditStatusOptions
  for (var i = cusAuditStatusOptions.length - 1; i >= 0; i--) {
    if(cusAuditStatusOptions[i].key==auditStatus){
      return cusAuditStatusOptions[i].display_name
    }
  }
  return auditStatus
}

export function parseCustSource(custSource){
  const custSourceOptions=store.getters && store.getters.custSourceOptions
  for (var i = custSourceOptions.length - 1; i >= 0; i--) {
    if(custSourceOptions[i].key==custSource){
      return custSourceOptions[i].display_name
    }
  }
  return custSource
}

export function parseWithdrawStatus(depWithStatus){
  const withdrawStatusOptions=store.getters && store.getters.withdrawStatusOptions
  for (var i = withdrawStatusOptions.length - 1; i >= 0; i--) {
    if(withdrawStatusOptions[i].key==depWithStatus){
      return withdrawStatusOptions[i].display_name
    }
  }
  return depWithStatus
}

export function parseDepositStatus(depWithStatus){
  const depositStatusOptions=store.getters && store.getters.depositStatusOptions
  for (var i = depositStatusOptions.length - 1; i >= 0; i--) {
    if(depositStatusOptions[i].key==depWithStatus){
      return depositStatusOptions[i].display_name
    }
  }
  return depWithStatus
}

export function parsePayType(payType){

  const payTypeOptions=store.getters && store.getters.payTypeOptions
  for (var i = payTypeOptions.length - 1; i >= 0; i--) {
    if(payTypeOptions[i].value == payType){
      return payTypeOptions[i].label
    }
  }
  return payType
}

export function parseOutType(payType){

  const outTypeOptions=store.getters && store.getters.outTypeOptions
  for (var i = outTypeOptions.length - 1; i >= 0; i--) {
    if(outTypeOptions[i].value == payType){
      return outTypeOptions[i].label;
    }
  }
  return payType
}

export function parsePayStatus(payStatus){

  const outTypeOptions=store.getters && store.getters.payStatusOptions
  for (var i = outTypeOptions.length - 1; i >= 0; i--) {
    if(outTypeOptions[i].key == payStatus){
      return outTypeOptions[i].display_name
    }
  }
  return payStatus
}

export function parseTradeCmd(tradecmd){
  const tradeCmdOptions=store.getters && store.getters.tradeCmdOptions
  for (var i = tradeCmdOptions.length - 1; i >= 0; i--) {
    if(tradeCmdOptions[i].key==tradecmd){
      return tradeCmdOptions[i].display_name
    }
  }
  return tradecmd
}





